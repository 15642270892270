body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


/* src/index.css */
/* Add these styles to customize pagination appearance */
.pagination {
  display: inline-block;
}

.pagination button {
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #4a5568;
}

/* Add these styles to your global styles or CSS file */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 2px solid #4a90e2;
  color: #4a90e2;
  background-color: #fff;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.btn:hover {
  background-color: #4a90e2;
  color: #fff;
}
